import { render, staticRenderFns } from "./tdjl.vue?vue&type=template&id=75052769&scoped=true&"
import script from "./tdjl.vue?vue&type=script&lang=js&"
export * from "./tdjl.vue?vue&type=script&lang=js&"
import style0 from "./tdjl.vue?vue&type=style&index=0&id=75052769&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75052769",
  null
  
)

export default component.exports